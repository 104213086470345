//FuncionarioList.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BounceLoader } from 'react-spinners';
import Sidebar from './SideBar';
import './FuncionarioListForm.css'; // Importa o CSS específico para ContractsList
import { useNavigate } from 'react-router-dom'; // Importa useNavigate para redirecionamento
import { GrAdd, GrEdit, GrTrash } from "react-icons/gr"; // Importa o ícone de lixeira

const FuncionarioListForm = () => {
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedFuncionarios, setSelectedFuncionarios] = useState([]); // Armazena os IDs dos funcionarios selecionados
  const navigate = useNavigate(); // Hook para redirecionamento

  useEffect(() => {
    const fetchFuncionarios = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token de acesso não encontrado.');
        }

        const response = await axios.get('https://financeiro.multipla.tec.br/api/funcionario-list/', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        console.log('Response:', response.data); // Verifica a resposta

        // Verifica se a resposta contém contratos
        if (Array.isArray(response.data)) {
          setContracts(response.data);
        } else {
          throw new Error('Dados recebidos não estão no formato esperado.');
        }
      } catch (error) {
        console.error('Fetch error:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchFuncionarios();
  }, []);

  const handleButtonClick = () => {
    navigate('/cadastro-funcionario'); // Redireciona para a nova página
  };

  const handleSelectFuncionarios = (id) => {
    setSelectedFuncionarios(prevSelected =>
      prevSelected.includes(id)
        ? prevSelected.filter(funcionarioId => funcionarioId !== id)
        : [...prevSelected, id]
    );
  };

  const handleDeleteFuncionarios = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token de acesso não encontrado.');
      }

      // Faz a requisição para o soft delete
      await axios.post('https://financeiro.multipla.tec.br/api/funcionarios/soft-delete/', {
        ids: selectedFuncionarios  // Envia a lista de IDs dos funcionarios selecionados
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      // Remove os funcionarios deletados da lista no frontend
      const updatedContracts = contracts.filter(
        (funcionario) => !selectedFuncionarios.includes(funcionario.id)
      );
      setContracts(updatedContracts);
      setSelectedFuncionarios([]);  // Limpa a seleção após a exclusão

    } catch (error) {
      console.error('Erro ao deletar funcionarios:', error);
    }
  };


  if (loading) {
    return <div className="loader-container"><BounceLoader color="#009C95" size={50} /></div>;
  }

  if (error) {
    return <div className="error">Erro: {error}</div>;
  }

  return (
    <div className="funcionario-list-container"> {/* Novo contêiner flex */}
      <Sidebar /> {/* Renderiza a Sidebar */}
      <div className="funcionario-list">
        <div className="actions">
          <input
            type="text"
            placeholder="Pesquisar Funcionario ..."
            value={""}
            onChange={""}
          />
          <button className="add-button" onClick={handleButtonClick}>
            <GrAdd /> Incluir Funcionario
          </button>

          {/* Mostrar o botão de excluir apenas quando houver funcionarios selecionados */}
        {selectedFuncionarios.length > 0 && (
          <button className="delete-button" onClick={handleDeleteFuncionarios}>
            <GrTrash /> Excluir Selecionados
          </button>
        )}

        </div>
        <h1>Funcionario Associados</h1>
        {contracts.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>Selecionar</th> {/* Coluna para checkboxes */}
                <th>Nome</th>
                <th>CPF/CNPJ</th>
                <th>Telefone</th>
                <th>Email</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
  {contracts.map(funcionario => (
    <tr key={funcionario.id}>
      <td>
        <input
          type="checkbox"
          checked={selectedFuncionarios.includes(funcionario.id)}
          onChange={() => handleSelectFuncionarios(funcionario.id)}
        />
      </td>
      <td>{funcionario.nome}</td>
      <td>{funcionario.cpf_cnpj}</td>
      <td>{funcionario.telefone}</td>
      <td>{funcionario.email}</td>
      <td>
        <button className="button-custom-edit" onClick={() => navigate(`/editar-funcionario/${funcionario.id}`)}>
          <GrEdit /> Editar
        </button>
      </td>
    </tr>
  ))}
</tbody>
          </table>
        ) : (
          <p>Nenhum contrato encontrado.</p>
        )}
      </div>
    </div>
  );
};

export default FuncionarioListForm;
