//
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ContaAReceberForm.css'; // Import the CSS file for styling
import SideBar from './SideBar';
import { FaSync } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ContaReceberList from './ContaReceberList';
import { GrAdd} from "react-icons/gr";
import { CgRemoveR } from "react-icons/cg";

function ContaReceberForm() {
    const [contratos, setContratos] = useState([]);
    const [formasPagamento, setFormasPagamento] = useState([]);
    const [projetos, setProjetos] = useState([]);
    const [contaFinanceira, setContaFinanceira] = useState([]);
    const [centrCusto, setCentroCusto] = useState([]);
    const [formData, setFormData] = useState({
        contrato: '',
        forma_pagamento: '',
        data_recebimento: '',
        competencia: '',
        conta_financeira: '',
        centro_custo: '',
        valor_total: 0,
        projetos: [],
    });
    const [selectedConta, setSelectedConta] = useState(null);
    const [contratoSelecionado, setContratoSelecionado] = useState(null);
    const [ultimaConta, setUltimaConta] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [showText, setShowText] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editingId, setEditingId] = useState(null);
    const [RefreshList, setRefreshList] = useState(null);
    const [proximoVencimento, setProximoVencimento] = useState(''); // Estado para armazenar o próximo vencimento
    const [totalContasRecebidas, setTotalContasRecebidas] = useState(null);
    const [loadingTotalContasRecebidas, setLoadingTotalContasRecebidas] = useState(true);
    const [totalContasPendentes, setTotalContasPendentes] = useState(null);
    const [loadingTotalContasPendentes, setLoadingTotalContasPendentes] = useState(true);

    useEffect(() => {
        console.log("Estado atual:", {
            isEditing,
            editingId,
            selectedConta
        });
    }, [isEditing, editingId, selectedConta]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('token'); // Recupera o token armazenado no localStorage

                if (!token) {
                    setError('Token não encontrado.');
                    return;
                }

                const headers = {
                    Authorization: `Bearer ${token}`
                };

                const [contratosResponse, formasPagamentoResponse, projetosResponse, contaFinanceirasResponse, centroCustoResponse] = await Promise.all([
                    axios.get('https://financeiro.multipla.tec.br/api/contratos/cliente/', { headers }),
                    axios.get('https://financeiro.multipla.tec.br/api/select/formas-pagamento/', { headers }),
                    axios.get('https://financeiro.multipla.tec.br/api/select/projetos/', { headers }),
                    axios.get('https://financeiro.multipla.tec.br/api/contas-financeiras/', { headers }),
                    axios.get('https://financeiro.multipla.tec.br/api/centros-custos/', { headers }),
                ]);

                setContratos(contratosResponse.data);
                setFormasPagamento(formasPagamentoResponse.data);
                setProjetos(projetosResponse.data);
                setContaFinanceira(contaFinanceirasResponse.data);
                setCentroCusto(centroCustoResponse.data);
            } catch (error) {
                setError('Erro ao buscar dados da API.');
                console.error(error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (selectedConta) {
            setFormData({
                contrato: selectedConta.contrato,
                forma_pagamento: selectedConta.forma_pagamento,
                data_pagamento: selectedConta.data_pagamento,
                competencia: selectedConta.competencia,
                conta_financeira: selectedConta.conta_financeira,
                centro_custo: selectedConta.centro_custo,
                valor_total: selectedConta.valor_total,
                projetos: selectedConta.projetos || []
            });
            setIsEditing(true);
            setEditingId(selectedConta.id);
        }
    }, [selectedConta]);

    useEffect(() => {
        // Chama a API de projeções de vencimento e obtém a data mais próxima
        const fetchProjecoesReceber = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    setError('Token não encontrado.');
                    return;
                }

                const headers = {
                    Authorization: `Bearer ${token}`,
                };

                const response = await axios.get('https://financeiro.multipla.tec.br/api/contratos/projecoes_receber/', { headers });
                const projecoes = response.data;

                // Obter a data atual
                const dataAtual = new Date();

                // Filtrar as projeções que possuem datas futuras e encontrar a mais próxima
                const proximasDatas = projecoes
                    .map(projecao => new Date(projecao.data_vencimento))
                    .filter(data => data > dataAtual)
                    .sort((a, b) => a - b);

                // Se houver uma data mais próxima, atualizar o estado
                if (proximasDatas.length > 0) {
                    setProximoVencimento(proximasDatas[0].toLocaleDateString('pt-BR'));
                } else {
                    setProximoVencimento('Nenhum vencimento próximo');
                }
            } catch (error) {
                setError('Erro ao buscar projeções de vencimento.');
                console.error(error);
            }
        };

        fetchProjecoesReceber();
    }, []); // Executa apenas uma vez, ao carregar o componente

    useEffect(() => {
        // Atualizar valor total quando os projetos mudam
        const total = formData.projetos.reduce((acc, projeto) => acc + parseFloat(projeto.valor || 0), 0);
        setFormData(prevFormData => ({ ...prevFormData, valor_total: total }));
    }, [formData.projetos]);


    useEffect(() => {
      const fetchTotalContasRecebidas = async () => {
        try {
          const token = localStorage.getItem('token');
          if (!token) {
            setError('Token não encontrado.');
            return;
          }

          const headers = {
            Authorization: `Bearer ${token}`,
          };

          const response = await axios.get('https://financeiro.multipla.tec.br/api/contas-a-receber/total-recebidas-ano', { headers });
          const total = response.data.total_recebidas_ano;

          if (total === undefined) {
            setError('Erro ao buscar total de contas recebidas.');
            return;
          }

          setTotalContasRecebidas(total);
          setLoadingTotalContasRecebidas(false);
        } catch (error) {
          setError('Erro ao buscar total de contas recebidas.');
          console.error(error);
          setLoadingTotalContasRecebidas(false);
        }
      };

      fetchTotalContasRecebidas();
    }, []);

    useEffect(() => {

      const fetchTotalContasPendentes = async () => {

        try {

          const token = localStorage.getItem('token');

          if (!token) {

            setError('Token não encontrado.');

            return;

          }


          const headers = {

            Authorization: `Bearer ${token}`,

          };


          const response = await axios.get('https://financeiro.multipla.tec.br/api/contas-a-receber/total_faturamento_receber/', { headers });

          const total = response.data.total_faturamento_receber;


          if (total === undefined) {

            setError('Erro ao buscar total de contas pendentes.');

            return;

          }


          setTotalContasPendentes(total);

          setLoadingTotalContasPendentes(false);

        } catch (error) {

          setError('Erro ao buscar total de contas pendentes.');

          console.error(error);

          setLoadingTotalContasPendentes(false);

        }

      };


      fetchTotalContasPendentes();

    }, []);

    const handleChange = async (event) => {
        const { name, value } = event.target;

        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value,
        }));

        if (name === 'contrato' && value) {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    setError('Token não encontrado.');
                    return;
                }

                const headers = {
                    Authorization: `Bearer ${token}`,
                };

                const contratoResponse = await axios.get(`https://financeiro.multipla.tec.br/api/contratos/${value}/`, { headers });
                setContratoSelecionado(contratoResponse.data);

                const ultimaContaResponse = await axios.get(`https://financeiro.multipla.tec.br/api/contas-receber/ultima-conta/?contrato_id=${value}`, { headers });
                setUltimaConta(ultimaContaResponse.data);

            } catch (error) {
                //setError('Erro ao buscar detalhes do contrato ou última conta.');
                console.error(error);
            }
        }
    };

    const preencherComUltimaConta = () => {
        if (ultimaConta) {
            setFormData({
                contrato: ultimaConta.contrato.id,
                forma_pagamento: ultimaConta.forma_pagamento.id,
                data_recebimento: ultimaConta.data_recebimento,
                competencia: ultimaConta.competencia,
                valor_total: ultimaConta.valor_total,
                projetos: ultimaConta.projetos.map(projeto => ({
                    projeto: projeto.id,
                    valor: projeto.valor,
                })),
            });
        }
    };

    const handleProjetoChange = (event, index) => {
        const { name, value } = event.target;
        const updatedProjetos = [...formData.projetos];
        updatedProjetos[index] = { ...updatedProjetos[index], [name]: value };
        setFormData(prevFormData => ({
            ...prevFormData,
            projetos: updatedProjetos,
        }));
    };

    const addProjeto = () => {
        setFormData(prevFormData => ({
            ...prevFormData,
            projetos: [...prevFormData.projetos, { projeto: '', valor: 0 }],
        }));
    };

    const removeProjeto = (index) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            projetos: prevFormData.projetos.filter((_, i) => i !== index),
        }));
    };

    const handleEdit = (conta) => {
        console.log("Conta a ser editada:", conta);

        // Garantir que os dados do projeto estão no formato correto
        const projetosFormatados = conta.projetos.map(projeto => ({
            projeto: projeto.id,
            valor: projeto.valor || conta.valor_total / conta.projetos.length
        }));

        setFormData({
            contrato: conta.contrato,
            forma_pagamento: conta.forma_pagamento,
            data_pagamento: conta.data_pagamento,
            competencia: conta.competencia,
            conta_financeira: conta.conta_financeira,
            centro_custo: conta.centro_custo,
            valor_total: parseFloat(conta.valor_total),
            projetos: projetosFormatados,
        });

        setIsEditing(true);
        setEditingId(conta.id);
        setSelectedConta(conta);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        console.log("Estado de edição:", { isEditing, editingId });
        console.log("Dados do formulário:", formData);

        try {
            const token = localStorage.getItem('token');
            if (!token) {
                setError('Token não encontrado.');
                return;
            }

            const headers = { Authorization: `Bearer ${token}` };

            // Definir o payload com os dados do formulário
            const payload = { ...formData };

            console.log("Tipo de requisição:", isEditing ? "PUT" : "POST");
            console.log("ID para edição:", editingId);

            let response;
            if (isEditing && editingId) {
                console.log("Enviando PUT para:", `https://financeiro.multipla.tec.br/api/contas-receber/${editingId}/`);
                response = await axios.put(
                    `https://financeiro.multipla.tec.br/api/contas-receber/${editingId}/`,
                    payload,
                    { headers }
                );
                toast.success('Conta a receber atualizada com sucesso!');
            } else {
                console.log("Enviando POST");
                response = await axios.post(
                    'https://financeiro.multipla.tec.br/api/contas-receber/',
                    payload,
                    { headers }
                );
                toast.success('Conta a receber criada com sucesso!');
            }

            setRefreshList(prev => !prev);
            resetForm();
            return response.data;
        } catch (error) {
            console.error('Erro completo:', error);
            console.error('Resposta do servidor:', error.response?.data);

            const errorMessage = error.response?.data?.detail ||
                (isEditing ? 'Erro ao atualizar conta a receber.' : 'Erro ao criar conta a receber.');

            setError(errorMessage);
            toast.error(errorMessage);
        }
    };

    // Função auxiliar para traduzir mensagens de erro comuns
    const translateErrorMessage = (field, message) => {
        const translations = {
            'This field may not be null.': 'Este campo não pode ser nulo.',
            'This field is required.': 'Este campo é obrigatório.',
            // Adicione mais traduções conforme necessário
        };

        const translatedMessage = translations[message] || message;
        const fieldName = translateFieldName(field);

        return fieldName ? `${fieldName}: ${translatedMessage}` : translatedMessage;
    };

    const calculatePercentage = (valor) => {
        if (contratoSelecionado && contratoSelecionado.valor_total && valor) {
            return (parseFloat(valor) / parseFloat(contratoSelecionado.valor_total) * 100).toFixed(2);
        }
        return 0;
    };

    const resetForm = () => {

        setFormData({

            contrato: '',

            forma_pagamento: '',

            data_pagamento: '',

            competencia: '',

            conta_financeira: '',

            centro_custo: '',

            valor_total: 0,

            projetos: []

        });

        setIsEditing(false);

        setEditingId(null);

        setSelectedConta(null);

    };

    // Função auxiliar para traduzir nomes de campos
    const translateFieldName = (field) => {
        const fieldTranslations = {
            'contrato': 'Contrato',
            'forma_pagamento': 'Forma de Pagamento',
            'data_pagamento': 'Data de Pagamento',
            'competencia': 'Competência',
            'valor_total': 'Valor Total',
            'projetos': 'Projetos',
            // Adicione mais traduções de campos conforme necessário
        };

        return fieldTranslations[field] || field;
    };

    return (
        <div style={{ display: 'flex' }}>
            <SideBar />
            <div className="container">
                <div className="cp-header">
                    <h1>Faturamento de Contrato</h1>
                    <div className="user-info">
                        <img src="https://via.placeholder.com/40" alt="Usuário" />
                        <span></span>
                    </div>
                </div>

                <div className="summary">
                    <div className="meu-card">

                        <h3>Total de Contas Recebidas</h3>

                        {loadingTotalContasRecebidas ? (

                          <p>Carregando...</p>

                        ) : totalContasRecebidas !== null ? (

                          <p> {totalContasRecebidas.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>

                        ) : (

                          <p>Nenhum valor encontrado</p>

                        )}

                      </div>
                    <div className="meu-card">
                        <h3>Próximo Vencimento</h3>
                        <p>{proximoVencimento}</p>
                    </div>
                    <div className="meu-card">

                      <h3>Contas Pendentes</h3>

                      {loadingTotalContasPendentes ? (

                        <p>Carregando...</p>

                      ) : totalContasPendentes !== null ? (

                        <p> {totalContasPendentes.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>

                      ) : (

                        <p>Nenhum valor encontrado</p>

                      )}

                    </div>
                </div>

                {contratoSelecionado && (
                    <div className="contract-info">
                        <p><strong>Contrato:</strong> {contratoSelecionado.numero}</p>
                        <p><strong>Descrição:</strong> {contratoSelecionado.descricao}</p>
                        <p><strong>Valor da Parcela:</strong> {contratoSelecionado.valor_total}</p>
                        <p><strong>Periodicidade de Vencimento:</strong> {contratoSelecionado.periodicidade_vencimento_receber}</p>
                        <p><strong>Data do Primeiro Vencimento:</strong> {contratoSelecionado.data_primeiro_vencimento_receber}</p>
                    </div>
                )}

                {ultimaConta && (
                    <button
                        type="button"
                        className="botao-preencher"
                        onClick={preencherComUltimaConta}
                        onMouseEnter={() => setShowText(true)}
                        onMouseLeave={() => setShowText(false)}
                        title="Preencher com a Última Conta" // Tooltip padrão do navegador
                    >
                        <FaSync />

                    </button>
                )}
                <div className="form-container">
                <form onSubmit={handleSubmit}>
                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="contrato">Contrato:</label>
                            <select id="contrato" name="contrato" value={formData.contrato} onChange={handleChange}>
                                <option value="">Selecione um contrato</option>
                                {contratos.map(contrato => (
                                    <option key={contrato.id} value={contrato.id}>{contrato.numero}</option>
                                ))}
                            </select>
                        </div>

                        <div className="form-group">
                            <label htmlFor="forma_pagamento">Forma de Pagamento:</label>
                            <select id="forma_pagamento" name="forma_pagamento" value={formData.forma_pagamento} onChange={handleChange} required>
                                <option value="">Selecione uma forma de pagamento</option>
                                {formasPagamento.map(forma => (
                                    <option key={forma.id} value={forma.id}>{forma.descricao}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="data_recebimento">Data de Recebimento:</label>
                            <input type="date" id="data_recebimento" name="data_recebimento" value={formData.data_recebimento} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="competencia">Competência:</label>
                            <input type="date" id="competencia" name="competencia" value={formData.competencia} onChange={handleChange} />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="contas_financeiras">Contas Financeiras:</label>
                            <select id="contas_financeiras" name="conta_financeira" value={formData.conta_financeira} onChange={handleChange}>

                                <option value="">Selecione uma Conta Financeira</option>

                                {contaFinanceira.map(conta => (

                                    <option key={conta.id} value={conta.id}>{conta.descricao}</option>

                                ))}

                            </select>
                        </div>

                        <div className="form-group">
                            <label htmlFor="centro_custos">Centro de Custos:</label>
                            <select id="centro_custos" name="centro_custo" value={formData.centro_custo} onChange={handleChange}>

                                <option value="">Selecione um Centro de custo</option>

                                {centrCusto.map(centro => (

                                    <option key={centro.id} value={centro.id}>{centro.descricao}</option>

                                ))}

                            </select>
                        </div>
                    </div>
                    <div className="project-group-container">
                    {formData.projetos.map((projetoItem, index) => (
                        <div key={index} className="project-group">
                            <select
                                name="projeto"
                                value={projetoItem.projeto}
                                onChange={(event) => handleProjetoChange(event, index)}
                            >
                                <option value="">Selecione um projeto</option>
                                {projetos.map(projeto => (
                                    <option key={projeto.id} value={projeto.id}>{projeto.nome}</option>
                                ))}
                            </select>
                            <span className="percentage-label">
                                {calculatePercentage(projetoItem.valor)}%
                            </span>
                            <input
                                type="number"
                                name="valor"
                                value={projetoItem.valor}
                                onChange={(event) => handleProjetoChange(event, index)}
                                placeholder="Valor"
                            />

                            <button type="button" className='remove-project-btn' onClick={() => removeProjeto(index)}>
                              <CgRemoveR />
                            </button>
                        </div>
                    ))}
                    <button type="button" className='add-project-btn' onClick={addProjeto}><GrAdd />Adicionar Projeto</button>
                </div>
                    <div className="total-group">
                        <label>Valor Total:</label>
                        <span>{formData.valor_total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                    </div>

                    {error && <p className="error-message">{error}</p>}
                    {successMessage && <p className="success-message">{successMessage}</p>}

                    <button type="submit" className='cp-submit-btn' disabled={loading}>{loading ? 'Salvando...' : 'Salvar'}</button>
                </form>
                </div>
            </div>
            <ContaReceberList />
            <ToastContainer />
        </div>
    );
}
export default ContaReceberForm;
